'use client';
import { MapControllers } from 'models/MapControllers';
import { createContext, useContext } from 'react';

export const MapControllerContext = createContext(MapControllers.ROUTE_PLANNER);

const useMapControllerContext = () => {
  const context = useContext(MapControllerContext);
  if (!context) {
    throw new Error('useMapController must be used within a MapControllerProvider');
  }
  return context;
};

export { useMapControllerContext };
