/* eslint-disable @typescript-eslint/no-unused-vars */
import { produce } from 'immer';
import { isSupportedCountry } from 'lib/map/getGeolocation';
import { redirectTo } from 'lib/redirect';
import { removeTripCookie, updateTripCookie } from 'lib/routePlanner/tripCookie';
import { updateTripUrl } from 'lib/routePlanner/updateTripUrl';
import { MapControllers } from 'models/MapControllers';
import { PublicURLModel } from 'models/PublicURLModel';
import { nanoid } from 'nanoid';
import { AtomEffect } from 'recoil';
import { geoIPSelector } from 'recoil/geoIP';
import { getPersistanceCacheData, persistanceCacheKey } from 'recoil/persistanceCacheEffect';

import {
  getDestinationPlace,
  RoutePlannerAtom,
  WaypointRecoilType
} from 'recoil/routePlanner/routePlannerAtom';

export const updateTripParamsEffect: AtomEffect<RoutePlannerAtom> = ({ onSet }) => {
  onSet((newRoutePlannerAtom, oldValue: RoutePlannerAtom, isReset) => {
    if (newRoutePlannerAtom.tripId !== oldValue.tripId) {
      updateTripCookie(newRoutePlannerAtom.tripId);
      updateTripUrl(newRoutePlannerAtom.tripId);
    } else if (isReset) {
      removeTripCookie();
      redirectTo(PublicURLModel.ROUTE);
    }
  });
};

export const setCurrentLocationWaypointEffect: AtomEffect<WaypointRecoilType[]> = ({
  trigger,
  getPromise,
  setSelf
}) => {
  if (trigger === 'get' && typeof window !== 'undefined') {
    if (!isSupportedCountry()) return;
    getPromise(geoIPSelector).then(({ latitude, longitude }) =>
      setSelf(
        produce((draft) => {
          if (draft[0] && draft[0].objectType) return;
          draft[0] = {
            id: nanoid(),
            lat: latitude,
            lon: longitude,
            objectType: 'currentLocation'
          };
        })
      )
    );
  }
};

export const setDefaultDestinationEffect =
  (controllerId: string): AtomEffect<WaypointRecoilType[]> =>
  ({ setSelf, trigger, getPromise }) => {
    if (controllerId !== MapControllers.ROUTE_PLANNER) return;
    if (trigger === 'get' && typeof window !== 'undefined') {
      const searchParams = new URL(location?.href).searchParams;
      const destination = searchParams.get('destination');
      if (!destination) return;

      getPromise(getDestinationPlace(destination)).then((place) => {
        setSelf(
          produce((draft) => {
            const lastWaypoint = draft[draft.length - 1];
            if (lastWaypoint.default && place.name) {
              const lastItemIndex = draft.length - 1;
              draft[lastItemIndex] = {
                ...place,
                ...(!place?.id && { id: nanoid() }),
                placeTypeId: place.placeType?.id,
                objectType: 'place'
              };
            }

            return draft;
          })
        );
      });
    }
  };

export const syncPersistentWaypointsEffect: AtomEffect<WaypointRecoilType[]> = ({ onSet }) => {
  onSet((newWaypoints) => {
    const newLocalStorageValue = produce(getPersistanceCacheData(), (draft) => {
      const filteredWaypoints = newWaypoints.filter((wp) => wp.objectType === 'place');

      draft['persistentWaypointsAtom__"route_planner"'] = filteredWaypoints;
    });

    localStorage.setItem(persistanceCacheKey, JSON.stringify(newLocalStorageValue));
  });
};
