import { ITripInstruction } from '@truckmap/common';
import { atom, atomFamily } from 'recoil';

export const mapRestrictionsPanelVisibleAtom = atomFamily<boolean, string>({
  key: 'mapRestrictionsPanelVisibleAtom',
  default: false
});

export type CurrentUserSettings = {
  fuelTypes?: {
    id: string;
    slug: string;
    display: string;
  };
  hazmatClasses?: {
    id: string;
    display: string;
    shortName: string;
  };
  equipmentTypes?: {
    id: string;
    display: string;
    name: string;
  };
  equipmentHeights?: {
    id: string;
    display: string;
    value: number;
    slug: string;
  };
  equipmentLengths?: {
    id: string;
    display: string;
    value: number;
    slug: string;
  };
  equipmentWeights?: {
    id: string;
    display: string;
    value: number;
    slug: string;
  };
  equipmentCommercialTypes?: {
    id: string;
    name: string;
    shortName: string;
    slug: string;
  };
};

export const currentUserSettingsAtom = atom<CurrentUserSettings>({
  key: 'currentUserSettings',
  default: {}
});

export const activeDirectionsAtom = atomFamily<ITripInstruction | null, string>({
  key: 'activeDirections',
  default: null
});

export const mapInvisibleLayersAtom = atomFamily<string[], string>({
  key: 'mapInvisibleLayers',
  default: []
});
