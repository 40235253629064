import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type DividerProps = ParentClassNameProp & {
  as?: 'div' | 'span' | 'li';
  spacing?: false | 'SM' | 'MD';
};

const useStyles = makeStyles<DividerProps>()({
  root: (props) => [props.className],
  divider: ({ spacing, className }) => [
    'w-full',
    'border-b border-gray-040',
    {
      'my-0': spacing === false,
      'my-2': spacing === 'MD',
      'my-1': spacing === 'SM'
    },
    className
  ]
});

// TODO: add to storybook
export const Divider: React.ComponentType<DividerProps> = React.memo(
  ({ className, as = 'div', spacing = 'MD' }) => {
    const styles = useStyles({ className, as, spacing });
    const WrapperElement = as;

    return <WrapperElement className={styles.divider} />;
  }
);

Divider.displayName = 'Divider';
