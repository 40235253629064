'use client';
import { redirectTo } from 'lib/redirect';
import { reverseUrl } from 'lib/reverseUrl';
import { PublicURLModel } from 'models/PublicURLModel';

let isChangingRoute = false;

export function updateTripUrl(tripId) {
  const currentPath = window.location.pathname;
  const currentTripId = new URLSearchParams(window.location.search).get('tripId');
  if (!isChangingRoute && currentPath.startsWith(PublicURLModel.ROUTE)) {
    if (tripId) {
      if (currentTripId !== tripId) {
        isChangingRoute = true;
        const newUrl = reverseUrl(PublicURLModel.ROUTE_TRIP, { tripId });
        redirectTo(newUrl);
      }
    } else if (currentTripId) {
      isChangingRoute = true;
      redirectTo(PublicURLModel.ROUTE);
    }
  }

  isChangingRoute = false;
}
