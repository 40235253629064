'use client';
import { useDynamicOffset } from 'hooks/useUI/useDynamicOffset';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { Tooltip, TooltipProps } from './Tooltip';
import { TooltipMotion } from './TooltipMotion';

export type TooltipNotificationProps = {
  disabled?: boolean;
  visible?: boolean;
  message: string;
  isSuccess?: boolean;
  showIcon?: boolean;
  manualPosition?: string;
  preRender?: boolean;
  hasOffset?: boolean;
} & ParentClassNameProp &
  Pick<TooltipProps, 'isSquare' | 'isSmall'> &
  ChildrenProp;

const useStyles = makeStyles({
  root: (props: TooltipNotificationProps) => ['relative', props.className],
  position: (props: TooltipNotificationProps) => [
    'absolute',
    {
      '-top-11 left-2': !props.manualPosition
    },
    props.manualPosition
  ]
});

const TooltipNotification: React.FC<TooltipNotificationProps> = memo(
  ({
    children,
    showIcon,
    message,
    visible = false,
    disabled = false,
    isSuccess,
    className,
    manualPosition,
    preRender = true,
    hasOffset = true,
    isSmall = false,
    isSquare = false
  }) => {
    const { offset, ref } = useDynamicOffset();
    const styles = useStyles({ className, manualPosition, offset });
    const tooltipElement = (
      <Tooltip
        message={message}
        isSuccess={isSuccess}
        showIcon={showIcon}
        isSquare={isSquare}
        isSmall={isSmall}
      />
    );

    return (
      <div className={styles.root}>
        {preRender && (
          <TooltipMotion visible={!disabled && visible} offsetX={hasOffset && offset}>
            <div ref={ref} className={styles.position}>
              {tooltipElement}
            </div>
          </TooltipMotion>
        )}
        {children}
      </div>
    );
  }
);

TooltipNotification.displayName = 'TooltipNotification';

export { TooltipNotification };
