import { isRedirectError } from 'next/dist/client/components/redirect';

export function redirectTo(path: string) {
  try {
    window.history.replaceState({ ...window.history.state, as: path, url: path }, '', path);
  } catch (error) {
    if (isRedirectError(error)) {
      throw error;
    }
  }
}
