export enum MapControllers {
  PLACE_SEARCH = 'place_search',
  PLACE_SEARCH_HERO = 'place_search_hero',
  ROUTE_PLANNER = 'route_planner',
  PLACE_DETAILS_HERO = 'place_details_hero',
  PLACE_DETAILS_PLACE_ANNOTATIONS = 'place_details_place_annotations',
  CARRIERS_ROUTE_PLANNER = 'carriers_route_planner',
  CARRIERS_ROUTE_PLANNER_INDEX = 'carriers_route_planner_index',
  DASHBOARD_SHIPMENT_DETAILS = 'dashboard_shipment_details',
  DASHBOARD_CARRIERS_ROUTE_PLANNER = 'dashboard_carriers_route_planner',
  TRACKING_PAGE = 'tracking_page',
  DISPATCH = 'dispatch',
  POST_CODES = 'post_codes',
  DEFAULT = 'default'
}
