import { IPlace, ITrip, LatLon } from '@truckmap/common';
import { GlobalAlertConfig } from 'components/common/Alert/AlertGlobalHotToast';
import { lookupPlace } from 'lib/place/lookupPlace';

import { MapControllers } from 'models/MapControllers';
import { nanoid } from 'nanoid';
import { atomFamily, selectorFamily } from 'recoil';
import { persistanceCacheEffect } from 'recoil/persistanceCacheEffect';
import {
  setCurrentLocationWaypointEffect,
  setDefaultDestinationEffect,
  syncPersistentWaypointsEffect,
  updateTripParamsEffect
} from 'recoil/routePlanner/routePlannerAtomEffects';
import { truckMapConfig } from 'truckMapConfig';

const isTenstreet = truckMapConfig.isTenstreet;

export type EmptyWaypoint = {
  id: string;
  name: string;
  objectType?: string;
  disabled?: boolean;
  default?: boolean;
  lonlat?: {
    coordinates?: [number, number];
  };
};

export type DefaultLocationWaypoint = {
  id: string;
  name: string;
  objectType: 'currentLocation' | 'destination';
  lat: number;
  lon: number;
  default?: boolean;
  osmId?: string;
};

export type WaypointRecoilType = EmptyWaypoint | DefaultLocationWaypoint;

export type RoutePlannerAtom = {
  tripId?: string;
  tripResultId?: string;
  hoveredTripResultId?: string;
  currentTrip?: ITrip;
  isCreatingTrip?: boolean;
  initialCoords?: LatLon;
  nearbyPlace?: IPlace;
  globalAlert?: GlobalAlertConfig;
};

export type WaypointsAtom = {
  hasExpandedWaypoints?: boolean;
  isAddingWaypoint?: boolean;
  isEditingOriginDestination?: boolean;
};

export const defaultWaypoints = [
  {
    id: nanoid(),
    name: ''
  },
  {
    id: nanoid(),
    name: '',
    default: true
  }
];

export const createTripTriggerAtom = atomFamily<number, string>({
  /**
   * This atom is used to trigger the creation of a trip
   */
  key: 'createTripTrigger',
  default: Date.now(),
  effects: []
});

export const getDestinationPlace = selectorFamily({
  key: 'getDestinationPlace',
  get: (address: string) => async () => {
    const response = await lookupPlace(address);
    return response;
  }
});

// this atom is only for waypoints that should reflect on localStorage
export const persistentWaypointsAtom = atomFamily<WaypointRecoilType[], string>({
  key: 'persistentWaypointsAtom',
  default: [],
  effects: [persistanceCacheEffect.persistAtom]
});

export const waypointsSettingsAtom = atomFamily<WaypointsAtom, string>({
  key: 'waypointsSettingsAtom',
  default: {
    hasExpandedWaypoints: true,
    isAddingWaypoint: false,
    isEditingOriginDestination: false
  }
});

export const waypointsAtom = atomFamily<WaypointRecoilType[], string>({
  key: 'waypointsAtom',
  default: defaultWaypoints,
  effects: (controllerId) =>
    controllerId === MapControllers.ROUTE_PLANNER
      ? [
          setCurrentLocationWaypointEffect,
          setDefaultDestinationEffect(controllerId),
          syncPersistentWaypointsEffect
        ]
      : [setCurrentLocationWaypointEffect, setDefaultDestinationEffect(controllerId)]
});

export const routePlannerAtom = atomFamily<RoutePlannerAtom, string>({
  key: 'routePlannerAtom',
  default: {
    tripId: null,
    tripResultId: null,
    hoveredTripResultId: null,
    isCreatingTrip: false,
    globalAlert: null
  },
  effects: (controllerId) =>
    controllerId === MapControllers.ROUTE_PLANNER && !isTenstreet ? [updateTripParamsEffect] : []
});
